<template>
  <CModal :show.sync="showModal" :centered="true" :title="$t('Crop image')" size="lg">
    <div class="text-center" style="height: auto;">
      <img id="crop-img" style="width: 100%;" />
    </div>
    <!--div>
      <div id="crop-preview" style="heigth: 100px;" />
    </div-->
    <template #footer>
      <CButton class="mr-2" color="secondary" @click="cancel">{{ $t('Cancel') }}</CButton>
      <CButton :disabled="!cropper" color="primary" @click="cropImage">{{ $t('Crop') }}</CButton>
    </template>
  </CModal>
</template>

<script>
const CONFIG = { minContainerWidth: 600, minContainerHeight: 600, minCropBoxWidth: 10, minCropBoxHeight: 10, aspectRatio: false, autoCrop: true, folder: 'default' } //, preview: '#crop-preview' }

/* eslint-disable @typescript-eslint/no-explicit-any */
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'ACropper',
  watch: {
    media(to) {
      console.log('Watch')
      //console.log(to, this.media?.url)
      if (to.url) this.openCropper()
    }
  },
  props: {
    media: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: () => {
        return {
          ...CONFIG
        }
      }
    }
  },
  data() {
    return {
      cropUrl: '',
      cropper: null,
      showModal: false
    }
  },
  methods: {
    openCropper() {
      this.destroyCropper() // TODO: while we found a way to force new options on current cropper

      if (this.media.url) {
        const self = this
        const options = { ...CONFIG, ...self.options }
        this.cropUrl = self.media.url.replace(':8000/', ':8080/') // TODO: workaroung CORS

        //console.log(options)

        document.getElementById('crop-img').setAttribute('src', this.cropUrl) // TODO: Fix CORS here ??
        self.$nextTick(() => {
          if (self.cropper !== null) {
            self.cropper.replace(this.cropUrl)
            //if (self.options.autoCrop) self.cropImage()
          } else {
            self.cropper = new Cropper(document.getElementById('crop-img'), options)
          }
          self.showModal = !!self.cropper
        })
      } else {
        // TODO: Alert no image
        alert('NO IMAGE')
      }
    },
    cropImage() {
      const self = this
      const canvas = self.cropper ? self.cropper.getCroppedCanvas() : false
      if (canvas) {
        canvas.toBlob(
          blob => {
            const media = { ...this.media, blob, cropped: true }
            self.$emit('imageCropped', media)
            self.showModal = false
            self.destroyCropper()
          } /*, 'image/png' */
        )
      } else {
        self.destroyCropper()
      } // TODO: Alert ?
    },
    destroyCropper() {
      if (this.cropper) {
        this.cropper.reset()
        this.cropper.destroy()
      }
      this.cropper = null
    },
    cancel() {
      this.$emit('imageCropped', { ...this.media, cropped: false })
      this.showModal = false
      this.destroyCropper()
    }
  }
}
</script>
